window.Theme = function () {
  this.init = function () {
    const theme = sessionStorage.getItem('inverted');

    if (theme === null || parseInt(theme) === 0) {
      document.body.classList.remove('inverted');
    }

    if (parseInt(theme) === 1) {
      document.body.classList.add('inverted');
    }
  }
}

new window.Theme().init();
